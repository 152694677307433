export default function ApplicationMark(props) {
    return (
        <svg {...props} viewBox="0 0 60 62.6">
            <g>
                <g id="Layer_1">
                    <polygon points="52.5 62.6 52.5 18 29.5 41.1 0 11.6 0 1.1 29.5 30.5 60 0 60 62.6 52.5 62.6" />
                    <polygon
                        fill="#0d9488"
                        points="29.5 61.2 0 31.7 0 21.2 29.5 50.7 29.5 61.2"
                    />
                </g>
            </g>
        </svg>
    );
}
