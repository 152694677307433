import ApplicationMark from "@/Components/ApplicationMark";
import { Card } from "@/Components/Catalyst/card";
import { Link } from "@inertiajs/react";

export default function Guest({ children }) {
    return (
        <div className="flex flex-col items-center min-h-screen pt-6 bg-gray-100 sm:justify-center sm:pt-0 gap-y-16">
            <div>
                <Link href="/">
                    <ApplicationMark className="w-20 h-20 fill-current text-zinc-950" />
                </Link>
            </div>
            <div className="w-full mt-6 sm:max-w-md">
                <Card>{children}</Card>
            </div>
        </div>
    );
}
